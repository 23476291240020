import { fetcher } from '../fetcher';

const PATIENTS_ENDPOINT = '/patients';

export const patientsService = {
  storeVisit: async (payload: VisitForm) => {
    const formData = objectToFormData({
      ...payload,
      files: payload.files.filter((file) => !file?.id),
    });

    return await fetcher(`${PATIENTS_ENDPOINT}/${payload.patient_id}/visits`, {
      method: 'POST',
      body: formData,
    });
  },

  getAllPatients: async () => {
    return await fetcher(`${PATIENTS_ENDPOINT}?per_page=10000`, {
      method: 'GET',
      schema: patientsSchema,
    });
  },

  getPatients: async ({
    search,
    page,
    per_page = 10,
    surveyIds,
    schematIds,
  }: {
    search: string;
    page: number;
    per_page?: number;
    surveyIds: number[];
    schematIds: number[];
  }) => {
    const surveyIdsQuery = surveyIds
      .map((id) => `survey_ids[]=${id}`)
      .join('&');
    const schematIdsQuery = schematIds
      .map((id) => `schemat_ids[]=${id}`)
      .join('&');

    const endpoint = `${PATIENTS_ENDPOINT}?search=${search}&page=${page + 1}&per_page=${per_page}&${surveyIdsQuery}&${schematIdsQuery}`;

    return await fetcher(endpoint, {
      method: 'GET',
      schema: patientMetaSchema,
      meta: true,
    });
  },

  editVisit: async (payload: VisitForm & { id: number }) => {
    const body: Omit<VisitForm, 'files'> = Object.keys(payload).reduce(
      (acc, key) => {
        if (key === 'files') {
          return acc;
        }

        return {
          ...acc,
          [key]: (payload as any)[key],
        };
      },
      {} as Omit<VisitForm, 'files'>,
    );

    return await fetcher(
      `${PATIENTS_ENDPOINT}/${payload.patient_id}/visits/${payload.id}`,
      {
        method: 'PATCH',
        body,
      },
    );
  },

  changeStatus: async ({
    id,
    patient_id,
    status,
  }: {
    id: number;
    patient_id: number;
    status: VisitStatus;
  }) => {
    return await fetcher(`${PATIENTS_ENDPOINT}/${patient_id}/visits/${id}`, {
      method: 'PATCH',
      body: {
        status,
      },
    });
  },

  storePatient: async (payload: PatientForm) => {
    return await fetcher(`${PATIENTS_ENDPOINT}`, {
      method: 'POST',
      body: payload,
      schema: patientSchema,
    });
  },

  getPatient: async (patient_id: number) => {
    return await fetcher(`${PATIENTS_ENDPOINT}/${patient_id}`, {
      method: 'GET',
      schema: patientSchema,
    });
  },

  deletePatient: async (patient_id: number) => {
    return await fetcher(`${PATIENTS_ENDPOINT}/${patient_id}`, {
      method: 'DELETE',
    });
  },

  editPatient: async (payload: PatientFormEdit, patient_id: number) => {
    return await fetcher(`${PATIENTS_ENDPOINT}/${patient_id}`, {
      method: 'PATCH',
      body: payload,
    });
  },

  attachPatientToSurvey: async (patient_id: number, survey_id: number) => {
    return await fetcher(`${PATIENTS_ENDPOINT}/${patient_id}`, {
      method: 'POST',
      body: {
        survey_id: survey_id,
      },
    });
  },

  deleteFileFromVisit: async (
    patient_id: number,
    visit_id: number,
    file_id: number,
  ) => {
    return await fetcher(
      `${PATIENTS_ENDPOINT}/${patient_id}/visits/${visit_id}/removeFile`,
      {
        method: 'DELETE',
        body: {
          file_id,
        },
      },
    );
  },

  addFilteToVisit: async (file: File, patient_id: number, visit_id: number) => {
    const formData = objectToFormData({
      files: [file],
    });

    return await fetcher(
      `${PATIENTS_ENDPOINT}/${patient_id}/visits/${visit_id}/uploadFile`,
      {
        method: 'POST',
        body: formData,
      },
    );
  },

  deleteVisit: async (payload: { patient_id: number; visit_id: number }) => {
    return await fetcher(
      `${PATIENTS_ENDPOINT}/${payload.patient_id}/visits/${payload.visit_id}`,
      {
        method: 'DELETE',
      },
    );
  },
};
